body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-body {
  /* background: #1D1D1D url(/faw_bg_1.png) no-repeat center bottom  !important ; */
  background: #060d49 !important ;
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: -12px;
  top: 0px;
  filter: drop-shadow(0px 4px 4px rgba(32, 31, 31, 0.25));
}

.appbar-header {
  box-shadow: none !important;
}

.withdraw-body {
  /* background: #1D1D1D url(/faw_bg_2.png) no-repeat center bottom  !important ; */
  background: #060d49 !important ;
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: -12px;
  top: 0px;
  filter: drop-shadow(0px 4px 4px rgba(32, 31, 31, 0.25));
}

.token-value {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.faw-text-gradient {
  background: -webkit-linear-gradient(left,#824CF5 , #0FBFFC);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faw-background-gradient {
  background: -webkit-linear-gradient(left,#824CF5 , #0FBFFC);
  /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}